import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';
import Img from 'gatsby-image';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';

import * as Styled from './styles';

const LessonSection = ({ data, pageContext }) => {
  const lessonSection = data.allMdx.edges;
  const { sectionTitle, sectionSubtitle, sectionDescription } = pageContext;

  return (
    <Layout>
      <SEO title={sectionTitle} description={sectionDescription}/>
      <Container section>
        <TitleSection title={sectionTitle} subtitle={sectionSubtitle} center />
        <Styled.Lessons>
          {lessonSection.map((item) => {
            const {
              id,
              fields: { slug },
              frontmatter: { chapterTitle, title, subtitle, image }
            } = item.node;

            return (
              <Styled.Lesson key={id}>
                <Link to={slug}>
                  <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 1 }}>
                    <Styled.Card>
                      {image && (
                        <Styled.Image>
                          <Img fluid={image.childImageSharp.fluid} alt={title} />
                        </Styled.Image>
                      )}
                      <Styled.Content>
                        <Styled.ChapterTitle>{chapterTitle}</Styled.ChapterTitle>
                        <Styled.Title>{title}</Styled.Title>
                        {subtitle &&  <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
                      </Styled.Content>
                    </Styled.Card>
                  </motion.div>
                </Link>
              </Styled.Lesson>
            );
          })}
        </Styled.Lessons>
      </Container>
    </Layout>
  );
};

export default LessonSection;

export const query = graphql`
  query LessonBySection($sectionAlias: String!) {
    allMdx(
      filter: { frontmatter: { section: { eq: $sectionAlias } } }
      sort: { fields: frontmatter___lessonSequence, order: ASC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            chapterTitle
            title
            subtitle
            description
            section
            image {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 240) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
