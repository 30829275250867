import styled from 'styled-components';
import tw from 'twin.macro';

export const Lessons = styled.div`
  ${tw`w-full flex flex-wrap p-2`};
`;

export const Lesson = styled.div`
  ${tw`w-full sm:w-1/3 p-4`};
`;

export const Card = styled.div`
  ${tw`w-full h-full rounded-lg flex flex-col overflow-hidden bg-white`};
`;

export const Content = styled.div`
  ${tw`p-4 text-indigo-900`};
`;

export const Image = styled.figure`
  ${tw``};
`;

export const ChapterTitle = styled.p`
  ${tw`text-sm text-gray-500 mt-1 mb-2`};
`;

export const Title = styled.h3`
  ${tw`text-xl font-semibold mb-4`};
`;

export const Subtitle = styled.p`
  ${tw`text-base`}
`;

export const DateModified = styled.h3`
  ${tw`text-xs text-blue-500`};
`;

